<template>
  <div class="" id="edit-participant-info">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 offset-md-2 p-5 bg-white mt-3 mb-3 rounded-3">
          <h3 class="text-start text-dark fw-bolder">Basic Information Edit Form</h3>
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="studentInfo.name" class="form-control" id="name" placeholder="Mr" required>
                    <label for="name">Participant name<span class="text-danger">*</span></label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="studentInfo.school" class="form-control" id="school" placeholder="xyz">
                    <label for="school">School name<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" id="classId" aria-label="Floating label select example" v-model="studentInfo.classId">
                      <option :value="null">--Select--</option>
                      <option :value="className.id" v-for="(className,key) in classes" :key="key">{{ className.name }}</option>
                    </select>
                    <label for="classId">Select Class<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" id="districtId" aria-label="Floating label select example" v-model="studentInfo.districtId">
                      <option :value="null">--Select--</option>
                      <option :value="district.id" v-for="(district,key) in districts" :key="key">{{ district.name }}</option>
                    </select>
                    <label for="districtId">Select District<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" name="email" v-model="studentInfo.email" class="form-control" id="email" placeholder="example@example.com" required>
                    <label for="mobile">Email address<span class="text-danger">*</span></label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" name="mobile" v-model="studentInfo.mobile" class="form-control" id="mobile" placeholder="01XXXXXXXXX" required>
                    <label for="mobile">Mobile<span class="text-danger">*</span>(Don't use Teletalk no.)</label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" v-model="studentInfo.gender" id="gender" aria-label="Floating label select example">
                      <option :value="null">--Select--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <label for="gender">Select Gender<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="studentInfo.postCode" class="form-control" id="postCode" placeholder="Mr">
                    <label for="postCode">Post code</label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-floating mb-3">
                    <textarea class="form-control" v-model="studentInfo.address" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                    <label for="floatingTextarea2">Detail address <span class="text-danger">*</span></label>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="row">
                <div class="col-12">
                  <image-upload :parentData="{avatarUrl:avatarUpdateUrl, participantId: id}" />
                </div>
              </div>
            </div>

            <div class="col-md-8 text-start">
              <div class="row">
                <div class="col-md-12 mb-md-0 mb-sm-3">
                  <button type="submit" @click="formSubmit" class="btn btn-success form-control" id="submit"><i class="fa fa-save"></i> Update My Information</button>
                </div>
<!--                <div class="col-md-6">-->
<!--                  <button type="reset" class="btn btn-danger form-control" id="reset"><i class="fa fa-times-circle"></i> Cancel Registration</button>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageUpload from "../components/ImageUpload";
  import Preloader from "../components/Preloader";
  export default {
    name: "EditParticipantInfo",
    data(){
      return {
        loaded:false,
        pageStatus:false,
        eventCode:sessionStorage.getItem('eventCode'),
        eventLogo:sessionStorage.getItem('eventLogo'),
        divisionId:JSON.parse(localStorage.getItem('participant')).division_id,
        id:JSON.parse(localStorage.getItem('participant')).id,
        avatarUpdateUrl:this.$http.options.root+'avatar/edit',
        districts:[],
        classes:[],
        studentInfo:{
          name:JSON.parse(localStorage.getItem('participant')).name,
          school:JSON.parse(localStorage.getItem('participant')).school,
          districtId:JSON.parse(localStorage.getItem('participant')).district_id,
          classId:JSON.parse(localStorage.getItem('participant')).class_id,
          mobile:JSON.parse(localStorage.getItem('participant')).mobile,
          email:JSON.parse(localStorage.getItem('participant')).email,
          gender:JSON.parse(localStorage.getItem('participant')).gender,
          postCode:JSON.parse(localStorage.getItem('participant')).post_code,
          address:JSON.parse(localStorage.getItem('participant')).address,
          ppUrl:JSON.parse(localStorage.getItem('participant')).avatar,
          id: JSON.parse(localStorage.getItem('participant')).id
        }
      }
    },
    components: {'image-upload':ImageUpload,Preloader},
    beforeCreate(){

    },
    created() {
      JSON.parse(localStorage.getItem('participant'));
      this.$http.get("get-participant/"+this.id).then(function (response) {
        this.districts = response.body.districts;
        this.classes = response.body.classes;
        this.loaded = true;
      })
    },
    methods:{
      formSubmit(){
        if (this.formCheck()){
          this.$http.post('participant-info-update', this.studentInfo).then(response => {
            if (response.body.status=='success'){
              localStorage.setItem('participant',JSON.stringify(response.body.participant));
              this.$router.push({name:'ParticipantProfile'});
            }else {
              alert(response.body.message);
            }
          });
        }
      },
      formCheck(){
        for (let index in this.studentInfo){
          if (this.studentInfo[index] === null && index !== 'postCode'){
            alert('You have to fill all field correctly');
            return false;
          }
        }
        return true;
      },
    }
  }
</script>

<style scoped>
  #edit-participant-info{
    background-color: #dddddd;
  }
  input, select, textarea {
    border-top: none;
    border-right: none;
  }
</style>